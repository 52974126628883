import { useRecalProcessAndGenVM } from "presentation/hook/Recalculation/useRecalProcessAndGenVM";
import { useANAInfoTracked } from "presentation/store/ANAInfo";
import { memo, useEffect, useState } from "react";
import { Loader, SliderPanel } from "veronica-ui-component/dist/component/core";
import { RecalProcessAndGenFromPanel } from "./RecalProcessAndGenFormPanel";
import RecalProcessAndGenTitleBar from "./RecalProcessAndGenTitleBar";

const RecalProcessAndGenMaintenance: React.FC = () => {
    const recalProcessAndGenVM = useRecalProcessAndGenVM();
    const [isLoading, setIsLoading] = useState(false);
    const [anaInfoState] = useANAInfoTracked();
    useEffect(() => {
        const initialScreen = async () => {
            try {
                setIsLoading(true);
                const results = await Promise.allSettled([
                    recalProcessAndGenVM.loadDropdownOption()
                ]);
                results?.forEach((result, index) => {
                    if (index === 0 && result.status === 'fulfilled') {

                    } else if (index === 1 && result.status === 'rejected') {

                    }
                })
            } catch (error) {
                setIsLoading(false);
            }
        }
        initialScreen().then((data) => {
            setIsLoading(false)
        }).catch(error => {
            setIsLoading(false)
        });
    }, [anaInfoState.userName, recalProcessAndGenVM])


    if (isLoading) return <Loader Indicator="Stripe" size="Large" />;

    return <>

        <div className={`main-comp-wrapper im-hide-side-form-draggable`}>
            <RecalProcessAndGenTitleBar />

            <SliderPanel
                isOpen={true}
                draggable={false}
                leftSectionWidth={true ? "100%" : "0%"}
                rightSectionWidth={true ? "0%" : "100%"}
                leftChildren={<RecalProcessAndGenFromPanel />}
            />
        </div>
    </>
}
export default memo(RecalProcessAndGenMaintenance);